import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FapModalComponent } from '../../fap-modal/fap-modal.component';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavService } from '../../../../services/nav.service';
import { Subscription } from 'rxjs';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

@Component({
  selector: 'fap-scheduler-automation',
  templateUrl: './fap-scheduler-automation.component.html',
  styleUrls: ['./fap-scheduler-automation.component.scss']
})
export class FapSchedulerAutomationComponent implements OnChanges, OnInit {

  @Input() public trigger: any = null;
  @Input() public triggerId: number = null;
  @Input() public schedulers: any = [];
  @Input() public currentFormType = null;

  @ViewChild('addEditTranslationsModal') addEditTranslationsModal: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('addEditSchedulerModal') addEditSchedulerModal: FapModalComponent;

  public automaticForm: FormGroup;
  public langString: string;
  private subscriptions: Array<Subscription> = [];
  public translation;
  public label: string = '';
  public automationForm = [];
  public autoFormFields = null;
  public automationFormData = null;
  public scheduler = null;
  public formType = null;
  public entityInfo = null;
  public formFields = null;
  public schedulerId: number = null;
  public closeCombo: boolean = true;

  @Output() public addTrigger: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateTrigger: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public emitSchedulers: EventEmitter<any> = new EventEmitter<any>();

  constructor(public globalRegistry: GlobalRegistryService, private navService: NavService, private fb: FormBuilder, public attributeService: AttributeService, public cacheService: CacheResolverService, public sensorService: SensorService, public typesService: TypesService, public toastr: ToastrService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
    this.initAutomaticForm();
  }

  initAutomaticForm() {
    this.automaticForm = this.fb.group({
      name: [
          this.trigger ? this.trigger['name'] : null,
          Validators.required,
      ],
      name_t: [
          this.trigger ? this.trigger['name_t'] : null,
          Validators.required,
      ],
      duration: [
        this.trigger ? this.trigger['duration'] : null,
        Validators.required,
      ],
      end: [
        this.trigger ? this.trigger['end'] : null,
        Validators.required,
      ],
      scheduler: [
        this.trigger ? this.trigger['scheduler'] : '',
        Validators.required,
      ],
      form: [
        this.trigger ? this.trigger['form'] : null,
        Validators.required,
      ],
    })
    if(this.trigger && this.trigger['name_t']) {
      const translation = this.getTranslation(this.trigger['name_t']);
      this.automaticForm.get('name').setValue(translation);
    }
  }

  updateForm() {
    if(this.formType && this.formFields.length > 0) {
      this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
        console.log(data);
      })
    }
  }

  addedScheduler(scheduler) {
    this.automaticForm.get("scheduler").setValue(scheduler.id);
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    this.schedulers.unshift(scheduler);
    this.closeCombo = true;
    this.emitSchedulers.emit(this.schedulers);
  }

  updatedScheduler(scheduler) {
    this.automaticForm.get("scheduler").setValue(scheduler.id);
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    const indexToUpdate = this.schedulers.findIndex(s => s.id === scheduler.id);
    if (indexToUpdate !== -1) {
      this.schedulers[indexToUpdate] = scheduler;
    }
    this.closeCombo = true;
    this.emitSchedulers.emit(this.schedulers);
  }  

  submitAutomation() {
    console.log(this.automaticForm.value);
    console.log(this.formType);
    
    let body = {
      name_t: this.automaticForm.value.name_t,
      duration: this.automaticForm.value.duration,
      scheduler: this.automaticForm.value.scheduler,
      end: this.automaticForm.value.end ? moment(this.automaticForm.value.end).format('YYYY-MM-DD') : null,
      form: this.formType.id
    }
    console.log(body);
    if(this.trigger) {
        this.sensorService.updateTrigger(this.trigger.id, body).subscribe(data => {
            console.log(data);
            this.updateTrigger.emit(data.body.results);
            this.toastr.success('Trigger updated successfully');
            this.automaticForm.reset();
        }, (error) => {
          this.toastr.error(error.error.results.error);
        })
    } else {
      this.sensorService.postTrigger(body).subscribe(data => {
        console.log(data);
        this.addTrigger.emit(data.body.results);
        this.toastr.success('Trigger added successfully');
        this.automaticForm.reset();
      }, (error) => {
        this.toastr.error(error.error.results.error);
      })
    }
    this.updateForm();
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
    }
  }

  editScheduler(scheduler) {
    console.log(scheduler);
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    this.addEditSchedulerModal.showModal();
  }

  deleteScheduler(schedulerId: number) {
    this.sensorService.deleteScheduler(schedulerId).subscribe(
        () => {
            this.schedulers = this.schedulers.filter(scheduler => scheduler.id !== schedulerId);
            this.toastr.success('Scheduler deleted successfully')
        },
        error => {
          this.toastr.error(error.error.results.error);
        }
    );
  }

  setScheduler(scheduler) {
    this.scheduler = scheduler;
    this.schedulerId = scheduler.id;
    this.automaticForm.get('scheduler').setValue(scheduler.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('triggerId') && this.triggerId == 0) {
      this.trigger = null;
      this.entityInfo = null;
      this.scheduler = null;
      this.schedulerId = null;
      this.initAutomaticForm();
      console.log(this.triggerId);
      this.getFormTypeById(this.currentFormType);
    } else {
      if(changes.hasOwnProperty('trigger') && this.trigger) {
        console.log(this.trigger);
        this.initAutomaticForm();
        this.schedulers.forEach(scheduler => {
          if(scheduler.id === this.trigger.scheduler) {
            this.scheduler = scheduler;
            this.schedulerId = scheduler.id;
          }
        });
        this.getFormTypeById(this.currentFormType);
      }
    }
  }

  public getFormTypeById(typeId: number) {
    const url = this.typesService.getUrl('form/');
    this.cacheService.delete(url+typeId);
    this.typesService.getFormTypeById(typeId).subscribe(data => {
      console.log(data);
      this.entityInfo = data.body.results;
    })
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  onAddUpdateTranslation(translation) {
      console.log(translation);
      this.translation = translation;
      this.automaticForm.controls['name_t'].setValue(translation.id);
      this.automaticForm.controls['name'].setValue(translation[this.langString]);
      this.addEditTranslationsModal.hideModal();
  }

  addEditTranslation(label) {
    this.label = label;
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.trigger);

    if (name === '' || type.nameT === null) {
        this.translation = null;
        this.addEditTranslationsModal.showModal();
        return;
    }

    for (const translation of this.globalRegistry.systemData.translations) {
        if (type && translation.id === type.nameT) {
            this.translation = translation;
            console.log(this.translation);
            this.addEditTranslationsModal.showModal();
            return;
        }
    }

    if (Object.keys(type).length === 0 && name) {
        const newTranslation = this.automaticForm.controls['name_t'].value;
        for (const translation of this.globalRegistry.systemData.translations) {
            if (translation.id === newTranslation) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
                return;
            }
        }
    }
  }

  createNewScheduler() {
    this.scheduler = null; 
    this.schedulerId = 0; 
    this.addEditSchedulerModal.showModal();
  }

}
