<div class="matrix_wrapper">
    <div class="odd_even d-flex  pad-border">
        <button class="btn mr-1" (click)="selectEven()">{{ 'Select odd' }}</button>
        <button class="btn ml-1" (click)="selectOdd()">{{ 'Select even' }}</button>
    </div>
    <div class="from_select pad-border">
        <div class="item_from mini_form">
            <span>From</span>
            <input placeholder="1" [max]="items.length" (blur)="updateRange('from', $event.target.value)" [(ngModel)]="rangeFrom"/>
        </div>
        <div class="item_to mini_form">
            <span>To</span>
            <input [placeholder]="items.length" [min]="rangeFrom ? rangeFrom : 1" (blur)="updateRange('to', $event.target.value)" [(ngModel)]="rangeTo"/>
        </div>
        <div class="select_btn">
            <button class="btn" (click)="selectRange()">{{ 'Select' }}</button>
        </div>
    </div>
    <div class="clear_btn  pad-border">
        <button class="btn clear" (click)="clear()">{{ 'Clear' }}</button>
    </div>
    <div class="matrix_table">
        <ng-container>
            <div class="matrix_col" *ngFor="let item of items">
                <span [class]="type" (click)="toggleItemSelection(item.id)" [ngClass]="{ 'active': checkDataPresence(item.id) }">{{ item.name }}</span>
            </div>
        </ng-container>
    </div>

    <div class="action_btns d-flex justify-content-end mt-4">
        <button class="btn mr-1" (click)="saveSelection()">{{ 'translations.save' | translate }}</button>
        <button class="btn ml-1" (click)="closeModal()">{{ 'translations.cancel' | translate }}</button>
    </div>
</div>