import { ResourceEntryModel } from './../../../../../core/models/stock/resource-entry.model';
import { PartyService } from './../../../../../core/services/api/company/party.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UtilsHelper } from './../../../../../core/heplers/utils.helper';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FieldInterface } from '../../../../../core/interfaces/field/field.interface';
import { FieldService } from '../../../../../core/services/api/farm/field.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { FieldModel } from '../../../../../core/models/field/field.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { LotService } from '../../../../../core/services/api/farm/lot.service';
import { CropInterface } from '../../../../../core/services/api/crop/data/crop.interface';
import { CropModel } from '../../../../../core/models/crops/crop.model';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { LotInterface } from '../../../../../core/interfaces/lot/lot.interface';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PrecisionService } from '../../../../../core/services/api/precision/precision.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { UnitValueModel } from '../../../../../core/models/units/unit-value.model';
import { NavService } from '../../../../../shared/services/nav.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AttributeRelationModel } from '../../../../../core/models/attribute/attribute-relation.model';
import { AttributeModel } from '../../../../../core/models/attribute/attribute.model';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { FapModalComponent } from '../../../../../shared/partials/components/fap-modal/fap-modal.component';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';

/**
 * @todo File has to many lines
 */

@Component({
    templateUrl: './lot-edit-container.component.html',
    styleUrls: ['./lot-edit-container.component.scss']
})
export class LotEditContainerComponent implements OnInit, OnDestroy {

    public editLotMode = false;
    public lotId: number; // this is only used in edit lot mode
    public farmId: number;

    @ViewChild('addAttributeModal')
    public addAttributeModal: FapModalComponent;

    public lotForm: UntypedFormGroup;
    public lotPolygon: MapPolygonInterface;
    public farmPolygon: MapPolygonInterface;

    public initialLotPolygon: MapPolygonInterface;
    public initialEditPolygon: MapPolygonInterface;

    public startNewCulture = false;
    public showCreateOrEditField = false;

    public selectedAttribute: AttributeModel = null;


    public lotFields: Array<FieldInterface> = [];
    public attributeRelations: Array<AttributeRelationModel> = [];
    public attributes: Array<AttributeModel> = [];
    public entityRelation = AttributeEntityEnum.Lot;

    public editFieldId: number;
    public editFieldName: string;
    public editFieldSoilType: string;
    public editPolygon: MapPolygonInterface;
    public editFieldArea: UnitValueModel;
    public editFieldOwner: number;
    public editRelations = false;
    public editAttributes = false;
    public addRelations = false;
    public enityRelations: AttributeEntityEnum.Lot = AttributeEntityEnum.Lot;

    // culture part
    public isNoCrop = true;
    public crop: CropInterface;
    public currentCrop: CropModel = null;
    public currentRelation: AttributeRelationModel;
    public deletedCropId: number = null;

    public reportId: number = null;
    public entityInfo: any;
    public showFormCombo = false;
    public formType;
    public formFields;

    @Output()
    public deleteLot: EventEmitter<number> = new EventEmitter();

    private subscriptions: Array<Subscription> = [];

    constructor(public mapService: MapService,
                public fieldService: FieldService,
                public lotService: LotService,
                public farmService: FarmService,
                public formBuilder: UntypedFormBuilder,
                public cropService: CropService,
                public stockService: StockService,
                public attributeService: AttributeService,
                public partyService: PartyService,
                public activatedRoute: ActivatedRoute,
                public router: Router,
                public navService: NavService,
                public toastr: ToastrService,
                public translate: TranslateService,
                public precisionService: PrecisionService,
                public globalRegistry: GlobalRegistryService,
                public widgetService: WidgetsService,
                public cacheService: CacheResolverService,
                public typesService: TypesService
                ) {
        this.lotForm = this.formBuilder.group({
            lotName: ['', Validators.compose([Validators.required])],
            farm: [null]
        });
        combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
            .subscribe(([params, queryParams]: [Params, Params]): void => {
                if (params['lotId'] !== undefined) {
                    // edit lot
                    this.editLotMode = true;
                    this.lotId = parseInt(params['lotId'], 10);
                    this.navService.createMod.next(false);
                    this.navService.editMod.next(true);
                    const url = lotService.getUrl('');
                    cacheService.delete(url);
                    this.lotService.getLot(this.lotId).pipe(take(1)).subscribe((lot: ResponseModel<LotModel>): void => {
                        if (lot.model.geopardId) {
                            this.mapService.showButtons = true;
                        }
                        this.farmId = lot.model.farm;
                        this.lotForm.controls['lotName'].setValue(lot.model.name);
                        this.lotPolygon = {
                            points: MapHelper.convertToAGMPolygon(lot.model.coords.coordinates[0]),
                            strokeColor: '#ffff00',
                            // fillColor: '#ffff00',
                            data: {lot: true},
                            isEditable: true
                        };
                        this.checkSubmitEnable();
                        this.initialLotPolygon = UtilsHelper.getDeepCopy(this.lotPolygon);
                        // this.initFarm();
                        this.showAllFieldsOnMap();
                        if (lot.model.currentCrop) {
                            this.isNoCrop = false;
                            this.currentCrop = lot.model.currentCrop;
                            this.crop = {
                                // cropType: this.currentCrop.cropType.id,
                                progress: this.currentCrop.progress,
                                start: this.currentCrop.start,
                                distRow: this.currentCrop.distRow,
                                distPlant: this.currentCrop.distPlant,
                                distUnit: this.currentCrop.distUnit,
                                // production: this.currentCrop.produced.map((resource: ResourceEntryModel): number => resource.id),
                                // usedResources: this.currentCrop.usedResources.map((resource: ResourceEntryModel): number => resource.id)
                            };
                        } else {
                            this.isNoCrop = true;
                        }
                    });

                    // this.fieldService.getFields({
                    //     lot: this.lotId,
                    //     offset: 0
                    // }).pipe(take(1)).subscribe((field: ResponseModel<FieldModel[]>): void => {
                    //     this.lotFields = field.model;
                    //     this.showAllFieldsOnMap();
                    // });
                    // this.navService.createMod.next(false);
                } else if (queryParams['farm'] !== undefined) {
                    // new lot
                    this.editLotMode = false;
                    this.farmId = parseInt(queryParams['farm'], 10);
                    this.initFarm();
                    this.navService.createMod.next(true);
                } else {
                    // this.router.navigate(['/']); // invalid params, redirect to home
                }
            });
    }

    generateSquareCoordinatesInsidePolygon(outerPolygonCoords: MapPolygonInterface): MapPolygonInterface {
        const [minLat, maxLat] = [Math.min(...outerPolygonCoords.points.map(coord => coord.lat)), Math.max(...outerPolygonCoords.points.map(coord => coord.lat))];
        const [minLng, maxLng] = [Math.min(...outerPolygonCoords.points.map(coord => coord.lng)), Math.max(...outerPolygonCoords.points.map(coord => coord.lng))];
      
        const centerLat = (minLat + maxLat) / 2;
        const centerLng = (minLng + maxLng) / 2;
      
        const sideLength = Math.min(maxLat - minLat, maxLng - minLng) / 4; // Adjust the divisor as needed
      
        const squareCoordinates = [
          { lat: centerLat - sideLength, lng: centerLng - sideLength },
          { lat: centerLat - sideLength, lng: centerLng + sideLength },
          { lat: centerLat + sideLength, lng: centerLng + sideLength },
          { lat: centerLat + sideLength, lng: centerLng - sideLength },
        ];
      
        const polygon: MapPolygonInterface = {
          points: squareCoordinates,
          fillColor: '#248a38',
          strokeColor: '#33de55',
          isEditable: true,
          data: {}
        };
      
        return polygon;
      }     

    public setFarm(ev) {
        this.mapService.resetMap();
        const queryParams = {
            farm: ev,
          };
         
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          });

        this.farmId = parseInt(queryParams['farm'], 10);
    }

    public getFormTypes() {
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url+'entity=lot');
        const params = {entity: 'lot'}
        this.typesService.getFormTypes(params).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results[0];
        })
      }

    public ngOnInit(): void {
        // const filters: {} = {
        //     relation__model: 'lot',
        // };
        this.activatedRoute.queryParams.subscribe(queryParams => {
            if(queryParams['from'] && queryParams['from']==='report') {
                this.reportId = queryParams['id'];
                this.showFormCombo = true;
                if(!this.lotForm.get('farm').value) {
                    this.lotForm.get('farm').setValue(this.globalRegistry.systemData.farms[0].id);
                    this.setFarm(this.globalRegistry.systemData.farms[0].id)
                }
            }
        })
        this.widgetService.setSize(6);
        this.attributes = this.globalRegistry.systemData.attributes;
        this.setupMap();
        this.mapService.mapZoom.next(18);
        this.navService.editFarm.next(true);
        this.navService.submitFarm.next(false);
        this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
            if (value) {
                this.submitLot();
            }
        }));

        this.subscriptions.push(this.navService.emitDelete.subscribe((value: boolean) => {
            if (value) {
                this.deleteCurrentLot();
            }
        }));

        this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                if(this.reportId) {
                    this.router.navigate(['/pages/alergotel_reports/'+this.reportId]);
                } else {
                    this.router.navigate(['/pages/lots']);
                }
                this.navService.emitCancel.next(false);
            }
        });
        this.checkSubmitEnable();
        this.getFormTypes();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.mapService.resetMap();
        this.mapService.mapMenuOptions = [];
        this.mapService.showButtons = false;
        this.navService.editFarm.next(false);
        this.navService.submitFarm.next(false);
    }

    public deleteField(fieldId: number): void {
        if (this.editLotMode) {
            this.fieldService.deleteField(fieldId).pipe(take(1)).subscribe((): void => {
                this.toastr.success(this.translate.instant('farm.field.fieldDeletedSuccessfully'));
                this.showAllFieldsOnMap();
            });
        }
        this.lotFields = this.lotFields.filter((field: FieldModel): boolean => field.id !== fieldId);
        this.showAllFieldsOnMap();
    }

    // public addedAttributeToLot($event: any): void {
    //     this.addAttributeModal.hideModal();
    // }

    public createField(): void {
        this.showCreateOrEditField = true;
        this.enablePolyEditMode('field');
        this.editFieldId = null;
        this.editFieldName = '';
        this.editFieldSoilType = '';
        this.editFieldArea = null;
        this.editPolygon = null;
        this.editFieldOwner = null;
        this.mapService.mapPolygons = [];
        this.initialEditPolygon = null;
    }

    public editField(field: FieldInterface): void {
        this.showCreateOrEditField = true;
        this.enablePolyEditMode('field');
        this.editFieldId = field.id; // if we edit a freshly created (not yet saved) field, we have negative ids
        this.editFieldName = field.name;
        this.editFieldArea = field.area as UnitValueModel;
        this.editFieldSoilType = field.soilType;
        this.editPolygon = {
            points: MapHelper.convertToAGMPolygon(field.coords.coordinates[0]),
            strokeColor: '#33de55',
            fillColor: '#248a38',
            data: {field: true},
            isEditable: true
        };
        this.initialEditPolygon = UtilsHelper.getDeepCopy(this.editPolygon);
        this.editFieldOwner = field.owner;
        this.mapService.mapPolygons = [this.editPolygon];
        this.mapService.centerMapOnPolygons();
    }

    public showAllFieldsOnMap(): void {
        this.enablePolyEditMode('lot');
        this.mapService.mapPolygons = [];
        if (this.lotPolygon) {
            this.mapService.mapPolygons.push(this.lotPolygon);
        }
        if (this.farmPolygon) {
            this.mapService.mapPolygons.push(this.farmPolygon);
        }
        // add the already existing fields
        this.lotFields.forEach((field: FieldModel): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(field.coords.coordinates[0]),
                strokeColor: '#33de55',
                fillColor: '#248a38',
                data: {field: true},
                isEditable: true
            });
        });
        this.mapService.centerMapOnPolygons();
    }

    public createdOrEditedField(fieldData: FieldInterface): void {
        if (this.editFieldId === null) {
            // created a new Field
            const newField: FieldInterface = {
                name: fieldData.name,
                soilType: fieldData.soilType,
                area: fieldData.area,
                coords: {
                    type: 'Polygon',
                    coordinates: [MapHelper.convertToAPIPolygon(this.editPolygon.points)]
                },
                lot: this.lotId,
                owner: fieldData.owner
            };
            if (this.editLotMode) {
                this.fieldService.createField(newField).pipe(take(1)).subscribe((field: ResponseModel<FieldModel>): void => {
                    this.lotFields.push(field.model);
                    this.exitCreateOrEditFieldMode();
                    this.toastr.success(this.translate.instant('farm.field.fieldCreatedSuccessfully'));
                });
            } else {
                this.lotFields.push(newField);
            }
        } else {
            let editedField: FieldInterface = null;
            editedField = this.lotFields.find((field: FieldModel): boolean => field.id === this.editFieldId);
            editedField.area = fieldData.area;
            editedField.name = fieldData.name;
            editedField.soilType = fieldData.soilType;
            editedField.owner = fieldData.owner;
            editedField.coords.coordinates[0] = MapHelper.convertToAPIPolygon(this.editPolygon.points);
            if (this.editLotMode) {
                this.fieldService.updateField(this.editFieldId, editedField).pipe(take(1)).subscribe((): void => {
                    this.toastr.success(this.translate.instant('farm.field.fieldUpdatedSuccessfully'));
                });
            }
        }
        this.exitCreateOrEditFieldMode();
    }

    public exitCreateOrEditFieldMode(): void {
        this.showAllFieldsOnMap();
        this.mapService.centerMapOnPolygons();
        this.showCreateOrEditField = false;
        this.enablePolyEditMode('lot');
    }

    public deleteCurrentLot() {
        console.log(this.lotId);
        this.lotService.deleteLot(this.lotId).subscribe(() => {
            this.globalRegistry.reloadLots();
            this.toastr.success(this.translate.instant('farm.lot.lotDeletedSuccessfully'));
            setTimeout(()=> {
                this.router.navigate(['/pages/lots']);
            }, 1000);
        });
    }

    public submitLot(): void {
        let lotObservable: Observable<ResponseModel<LotModel>> = null;
        if(!this.lotPolygon || !this.lotPolygon.points) {
            this.toastr.error(this.translate.instant('farm.lot.lotMustBeDrawnOnTheMap'));
            return
        }
        if (this.editLotMode) {
            const lot: LotInterface = {
                name: this.lotForm.controls['lotName'].value,
                coords: {
                    type: 'Polygon',
                    coordinates: [MapHelper.convertToAPIPolygon(this.lotPolygon.points)]
                }
            };
            lotObservable = this.lotService.updateLot(this.lotId, lot);
        } else {
            const lot: LotInterface = {
                farm: this.farmId,
                name: this.lotForm.controls['lotName'].value,
                coords: {
                    type: 'Polygon',
                    coordinates: [MapHelper.convertToAPIPolygon(this.lotPolygon.points)]
                }
            };
            lotObservable = this.lotService.createLot(lot);
        }
        lotObservable.pipe(take(1)).subscribe((lot: ResponseModel<LotModel>): void => {
            // this.globalRegistry.reloadLots();
            this.lotId = lot.model.id;
            this.globalRegistry.addLocalRelationsToService.next(this.lotId);
            let createFields: Array<Observable<{}>> = [];
            if (!this.editLotMode) {
                createFields = this.lotFields.map((field: FieldInterface): Observable<{}> => {
                    field.lot = this.lotId;
                    return this.fieldService.createField(field);
                });
            }
            const deleteCrop: Array<Observable<{}>> = this.deletedCropId !== undefined && this.deletedCropId !== null
                ? [this.cropService.deleteCrop(this.deletedCropId)]
                : [];
            const allObservables: Array<Observable<{}>> = [].concat(createFields, deleteCrop);

            const submitCropFunc: () => void = (): void => {
                // after all the previous requests return a response
                // (on this step we depend on the lot existence and the deletion of the crop)
                if (!this.isNoCrop) {
                    // we have a crop edited, determine if we have an existing crop which was not deleted on the previous step
                    if ((this.deletedCropId === null || this.deletedCropId === undefined) && this.currentCrop) {
                        // we have an existing crop, it was not deleted, update it
                        this.cropService.updateCrop(this.currentCrop.id, this.crop).pipe(take(1)).subscribe((): void => {
                            this.globalRegistry.reloadLots((): void => {
                                this.toastr.success(this.translate.instant('farm.lot.lotCreatedEditedSuccessfully'));
                                this.globalRegistry.newFarmOrLotAdded.next(true);
                                this.router.navigate(['/pages/lots']);
                            });
                        }, (): void => {
                            this.toastr.error(this.translate.instant('farm.crop.errorUpdatingCrop'));
                            this.router.navigate(['/pages/lots']);
                        });
                    } else {
                        // just begin a new crop
                        this.crop.lot = this.lotId;
                        this.cropService.beginCrop(this.crop).pipe(take(1)).subscribe((): void => {
                            this.globalRegistry.reloadLots((): void => {
                                this.toastr.success(this.translate.instant('farm.lot.lotCreatedEditedSuccessfully'));
                                this.globalRegistry.newFarmOrLotAdded.next(true);
                                this.router.navigate(['/pages/lots']);
                            });
                        }, (): void => {
                            this.toastr.error(this.translate.instant('farm.crop.failedToBeginCrop'));
                            this.router.navigate(['/pages/lots']);
                        });
                    }
                } else {
                    this.globalRegistry.reloadLots((): void => {
                        this.toastr.success(this.translate.instant('farm.lot.lotCreatedEditedSuccessfully'));
                        this.globalRegistry.newFarmOrLotAdded.next(true);
                        if(this.reportId) {
                            if(this.lotId) {
                                this.router.navigate(['/pages/alergotel_reports'+this.reportId], {queryParams: {lot: this.lotId}});
                            } else {
                                this.router.navigate(['/pages/alergotel_reports/'+this.reportId]);
                            }
                        } else {
                            this.router.navigate(['/pages/lots']);
                        }
                    });
                }
            };
            if (allObservables.length === 0) {
                submitCropFunc();
            } else {
                combineLatest(allObservables).pipe(take(1)).subscribe(submitCropFunc, (): void => {
                    this.toastr.error(this.translate.instant('farm.lot.errorCreatingEditingFieldsForLot'));
                    this.router.navigate(['/pages/lots']);
                });
            }
            this.updateAttributes();
        }, (): void => {
            this.toastr.error(this.translate.instant('farm.lot.errorCreatingLot'));
            this.router.navigate(['/pages/lots']);
        });
    }

    public updateAttributes() {
        if(this.formType && this.formFields.length > 0) {
            this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
            })
        }
    }

    public deleteCulture(): void {
        this.isNoCrop = true;
        // clear everything
        this.crop = {
            cropType: null,
            start: null,
            progress: null,
            end: null,
            distPlant: null,
            distRow: null,
            distUnit: null
        };
        // if we have an existing culture, we will keep the id for deletion on submit
        this.deletedCropId = this.currentCrop ? this.currentCrop.id : null;
    }

    // public addStockIncome(stockIncome: StockIncomeInterface): void {
    //     this.stockService.addStockIncome({...{farm: this.farmId}, ...stockIncome}).subscribe((response: ResponseModel<{}>): void => {
    //             this.toastr.success(this.translate.instant('stock.stockIncomeSucces'));
    //             this.cropService.updateCrop(this.currentCrop.id, {
    //                 production: response.body['resource']
    //             }).subscribe((): void => {
    //                 this.toastr.success(this.translate.instant('farm.crop.cropUpdatedSuccessfully'));
    //                 this.globalRegistry.reloadLots((): void => this.reloadCurrentCrop());
    //             }, (): void => {
    //                 this.toastr.error(this.translate.instant('farm.crop.errorUpdatingCrop'));
    //             });
    //         },
    //         (): void => {
    //             this.toastr.error(this.translate.instant('stock.stockIncomeError'));
    //         });
    // }

    // public addStockOutcome(stockOutcome: StockOutcomeInterface): void {
    //     this.stockService.addStockOutcome({...{farm: this.farmId}, ...stockOutcome}).subscribe((response: ResponseModel<{}>): void => {
    //             this.toastr.success(this.translate.instant('stock.stockOutcomeSucces'));
    //             this.cropService.updateCrop(this.currentCrop.id, {
    //                 usedResources: response.body['resources']
    //             }).subscribe((): void => {
    //                 this.toastr.success(this.translate.instant('farm.crop.cropUpdatedSuccessfully'));
    //                 this.globalRegistry.reloadLots((): void => this.reloadCurrentCrop());
    //             }, (): void => {
    //                 this.toastr.error(this.translate.instant('farm.crop.errorUpdatingCrop'));
    //             });
    //         },
    //         (): void => {
    //             this.toastr.error(this.translate.instant('stock.stockOutcomeError'));
    //         });
    // }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).pipe(take(1)).subscribe(
            (): void => {
                this.globalRegistry.reloadParties();
                this.toastr.success(this.translate.instant('people.party.partyDeletedSuccessfully'));
            }, (): void => {
                this.toastr.success(this.translate.instant('people.party.errorDeletingParty'));
            }
        );
    }

    public checkSubmitEnable(): void {
        if (!this.lotPolygon || this.lotForm.invalid || (!this.isNoCrop && this.crop?.cropType === null)) {
            this.navService.invalidForm.next(false);
            this.navService.submitFarm.next(true);
        } else {
            this.navService.invalidForm.next(true);
        }
    }

    public closeModals(): void {
        this.addAttributeModal.hideModal();
    }

    private setupMap(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        this.mapService.isEditMode = true;
        this.mapService.mapHasContextMenu = true;
        const mapServiceContext: MapService = this.mapService;
        const thisContext: this = this;
        this.mapService.mapZoom.next(18)
        this.mapService.mapMenuOptions = [
            {
                header: this.translate.instant('map.actions.polygonHeader'),
            },
            {
                text: this.translate.instant('map.actions.drawPolygon'),
                clickFunction(): void {
                    if (mapServiceContext.mapPolygons.filter((poly: MapPolygonInterface): boolean => poly.isEditable).length === 0) {
                    mapServiceContext.mapPolygons = [];
                    mapServiceContext.drawNewPolygon();
                    }
                }
            },
            {
                text: this.translate.instant('map.actions.clearPolygons'),
                clickFunction(): void {
                    mapServiceContext.mapPolygons = mapServiceContext.mapPolygons
                        .filter((poly: MapPolygonInterface): boolean => !poly.isEditable);
                    if (!thisContext.showCreateOrEditField) {
                        // in case we are in the edit lot mode
                        thisContext.lotPolygon = null;
                    } else {
                        thisContext.editPolygon = null;
                    }
                }
            },
            {
                text: this.translate.instant('revertChanges'),
                clickFunction(): void {
                    mapServiceContext.clickedRevertBtn.emit();
                }
            }
        ];
        this.subscriptions.push(this.mapService.onLastPolyPathChange.subscribe((polygon: MapPolygonInterface): void => {
            polygon.isEditable = true;
            polygon.data = {};
            if (this.showCreateOrEditField) {
                this.editPolygon = polygon;
                polygon.data['field'] = true;
            } else {
                this.lotPolygon = polygon;
                polygon.data['lot'] = true;
                // polygon.fillColor = '#ffff00';
                polygon.strokeColor = '#ffff00';
            }
            this.checkSubmitEnable();
        }));

        /**
         * @todo should be refactored, the revert function should be made my
         * map service / component
         */
        this.subscriptions.push(this.mapService.clickedRevertBtn.subscribe((): void => {
            if (this.showCreateOrEditField) {
                if (this.editPolygon && this.initialEditPolygon) {
                    this.editPolygon = UtilsHelper.getDeepCopy(this.initialEditPolygon);
                    this.mapService.clearEditablePolygons();
                    this.mapService.mapPolygons.push(this.editPolygon);
                } else if (this.initialEditPolygon) {
                    this.editPolygon = UtilsHelper.getDeepCopy(this.initialEditPolygon);
                    this.mapService.mapPolygons.push(this.editPolygon);
                } else {
                    this.mapService.clearEditablePolygons();
                    this.editPolygon = null;
                }
            } else {
                if (this.lotPolygon && this.initialLotPolygon) {
                    this.lotPolygon = UtilsHelper.getDeepCopy(this.initialLotPolygon);
                    this.mapService.clearEditablePolygons();
                    this.mapService.mapPolygons.push(this.lotPolygon);
                } else if (this.initialLotPolygon) {
                    this.lotPolygon = UtilsHelper.getDeepCopy(this.initialLotPolygon);
                    this.mapService.mapPolygons.push(this.lotPolygon);
                } else {
                    this.mapService.clearEditablePolygons();
                    this.lotPolygon = null;
                }
            }
            this.mapService.onPalygonDrawed.next();
            this.checkSubmitEnable();
        }));
    }

    private enablePolyEditMode(polyData: string, enable = true): void {
        this.mapService.mapPolygons.forEach((polygon: MapPolygonInterface): void => {
            if (polygon.data && polygon.data[polyData]) {
                polygon.isEditable = enable;
            } else {
                polygon.isEditable = enable;
            }
        });
        this.mapService.onPalygonDrawed.next();
    }

    private initFarm(): void {
        this.farmService.getFarm(this.farmId).pipe(take(1)).subscribe((farm: ResponseModel<FarmModel>): void => {
            this.farmPolygon = {
                points: MapHelper.convertToAGMPolygon(farm.model.coords.coordinates[0]),
                // fillColor: '#ff0000',
                strokeColor: '#ff0000',
                isEditable: false
            };
            this.mapService.mapPolygons.push(this.farmPolygon);
            this.mapService.centerMapOnPolygons();
        });
        // get polygon length
        this.mapService.lastPoligonTouchedSelectedLenght.next(this.mapService.getLengthOfPolygon(this.farmPolygon));
        setTimeout(() => {
            const innerPolygonCoords = this.generateSquareCoordinatesInsidePolygon(this.farmPolygon);
            console.log(innerPolygonCoords);
            this.lotPolygon = innerPolygonCoords;
            this.mapService.mapPolygons.push(this.lotPolygon);
        }, 1000)
    }

    private reloadCurrentCrop(): void {
        this.currentCrop = this.globalRegistry.systemData.lots.find(
            (lot: LotModel): boolean => lot.currentCrop ? lot.id === this.lotId : false).currentCrop;
        if (this.currentCrop) {
            this.crop = {
                // cropType: this.currentCrop.cropType.id,
                progress: this.currentCrop.progress,
                start: this.currentCrop.start,
                end: this.currentCrop.end,
                distPlant: this.currentCrop.distPlant,
                distRow: this.currentCrop.distRow,
                distUnit: this.currentCrop.distUnit,
                // production: this.currentCrop.produced.map((resource: ResourceEntryModel): number => resource.id),
                // usedResources: this.currentCrop.usedResources.map((resource: ResourceEntryModel): number => resource.id)
            };
        }

    }

    private identify(index: number, attr: AttributeRelationModel): number {
        return attr.id;
    }

}
