import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { PasswordPatternValidator } from '../../../auth-views/fap_views/fap_confirm-account/fap_password-pattern.validator';
import { FapConfirmPasswordValidator } from '../../../auth-views/fap_views/fap_confirm-account/fap_confirm-password.validator';
import { FapModalComponent } from '../../../../../shared/partials';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { PersonInterface } from '../../../../../core/services/api/company/data/person.interface';
import { PersonUserInterface } from '../../../../../core/services/api/company/data/person-user.interface';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { AddUpdatePersonUserInterface } from '../../../../../core/interfaces/people/add-update-person-user.interface';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PeopleGroupModel } from '../../../../../core/models/groups/people-group.model';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavService } from '../../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AddressModel } from '../../../../../core/models/company/address.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { ResponseModel } from 'src/app/core/models/response.model';
import { PartyService } from 'src/app/core/services/api/company/party.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from '../../../../../../environments/environment';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';

@Component({
    selector: 'fap-add-edit-person',
    templateUrl: './fap_add-edit-person.component.html',
    styleUrls: ['./fap_add-edit-person.component.scss']
})
export class FapAddEditPersonComponent implements OnChanges, OnInit, OnDestroy{

    @Input()
    public person: PersonModel;

    @Input()
    public personId: number;

    public parties: Array<PartyModel> = [];

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public groups: Array<PeopleGroupModel> = [];

    @Output()
    public addedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public updatedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteGroup: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteUser: EventEmitter<number> = new EventEmitter();

    @Output()
    public addPerson: EventEmitter<FormData> = new EventEmitter();

    @Output()
    public deletePerson: EventEmitter<number> = new EventEmitter();

    @Output()
    public updatePerson: EventEmitter<PersonInterface> = new EventEmitter();

    @Output()
    public addUser: EventEmitter<PersonUserInterface> = new EventEmitter();

    @Output()
    public updateUser: EventEmitter<PersonUserInterface> = new EventEmitter();

    @Output()
    public addUpdatePersonUser: EventEmitter<AddUpdatePersonUserInterface> = new EventEmitter();

    @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;

    @ViewChild('addEditGroupModal')
    public addEditGroupModal: FapModalComponent;

    @ViewChild('deleteUserModal')
    public deleteUserModal: FapModalComponent;

    @ViewChild('deleteGroupModal')
    public deleteGroupModal: FapModalComponent;

    public entityRelation = AttributeEntityEnum.Person;
    
    public deleteUserModalButtonPrimaryInterface: FapModalButtonInterface;
    public deleteUserModalButtonSecondaryInterface: FapModalButtonInterface;
    public deleteGroupModalButtonPrimaryInterface: FapModalButtonInterface;
    public deleteGroupModalButtonSecondaryInterface: FapModalButtonInterface;

    public editPersonForm: UntypedFormGroup;

    public editUserForm: UntypedFormGroup;

    public newUser = false;

    public userExists = false;

    public selectedParty: PartyModel;

    public selectedGroup: number;

    public innerImage: string | ArrayBuffer = '';

    public profilePicture: File;

    public userGroups: Array<number> = [];

    public noProfilePictureError =  false;
    public pic = false;
    public partyNotSelected = false;
    public fullAddress;
    public getMoreParties = true;
    public selectedPartyId: number;

    @Output()
    public deleteAddress: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @Output()
    public updatedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @ViewChild('addEditAddressModal')
    public addEditAddressModal: FapModalComponent;

    @ViewChild('fapaeds')
    public selectContainer: HTMLElement;

    public selectedAddress: AddressModel;

    private subscriptions: Array<Subscription> = [];
    public limit: 20;
    public partyToken: { limit: number; offset: number; search: string; } = null;
    public searchKeyword = '';
    public status = false;
    public mediaUrl = environment.mediaUrl;
    public entityInfo: any;
    public formType;
    public formFields;

    constructor(private formBuilder: UntypedFormBuilder,
                private translateService: TranslateService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public navService: NavService,
                private tosterService: ToastrService,
                public globalRegistry: GlobalRegistryService,
                public companyService: CompanyService,
                public toastr: ToastrService,
                public mapService: MapService,
                public partyService: PartyService,
                public cacheService: CacheResolverService,
                public typesService: TypesService,
                public attributeService: AttributeService
                ) {
        this.initEditPersonForm();
        this.initEditUserForm();
    }

    public ngOnInit(): void {
        this.initDeleteUserModalButtons();
        this.initDeleteGroupModalButtons();
        this.activatedRoute.queryParams.subscribe((queryParams:Params)=>{
            if(queryParams["farm"]!==undefined){
                this.navService.createMod.next(true);
                this.editPersonForm.controls.activeFarms.setValue([+queryParams["farm"]]);
            }
        });
        this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
            if (value) {
                this.submit();
                this.updateAttributes();
            }
        }));

        this.subscriptions.push(this.navService.emitDelete.subscribe((value: boolean) => {
            if (value) {
                this.deleteCurrentPerson();
            }
        }));

        this.subscriptions.push(this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['/pages/people']);
                this.navService.emitCancel.next(false);
            }
        }));
        this.getFormTypes();
    }

    public getFormTypes() {
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url+'entity=person');
        const params = {entity: 'person'}
        this.typesService.getFormTypes(params).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results[0];
        })
      }

    public updateAttributes() {
        if(this.formType && this.formFields.length > 0) {
            this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
            })
        }
    }

    public getParties() {
        const url = this.partyService.getUrl('');
        this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
        this.subscriptions.push(this.partyService.getParties({ limit: this.limit, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
          console.log(data);
          this.parties = data.body.results;
          this.partyToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
            if(this.partyToken) this.getMoreParties = true;
            const bool = this.parties.some(p => p.id === this.selectedPartyId);
            if(!bool) {
                this.status = true;
            }
        }))
    }

    public checkDefaultPartyExistence() {
        const INpartyFromPresence = this.parties.some(p => p.id == this.selectedPartyId)
        if(!INpartyFromPresence) {
          this.subscriptions.push(this.partyService.getParty(this.selectedPartyId).subscribe(data => {
            this.parties.unshift(data.body.results);
          }))
        }
    }

    scrollParties() {
        const url = this.partyService.getUrl('');
        console.log('parties scrolled');
        if(this.getMoreParties) {
        this.partyToken &&
        this.subscriptions.push(this.partyService.getParties(this.partyToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search);
            console.log(data);
            if(this.parties) {
              this.parties = [...this.parties, ...data.body.results];
            } else {
              this.parties = data.body.results;
            }
            if(data.body.next == null) {
              this.getMoreParties = false;
              return
            } else {
              const url = data.body.next.split('?')
              const urlParams = new URLSearchParams(url[1]);
              const entries = urlParams.entries();
              const params = this.paramsToObject(entries);
              console.log(params);
              if(this.partyToken.offset != params['offset']) {
              this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
              } else {
                return
              }
            }
          }));
        } else {
          return
        }
      }

      paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }

    public getAddress(addressId) {
        this.companyService.getSingleAddress(addressId).subscribe(data => {
          console.log(data);
          this.fullAddress = data.body.results;
        })
      }

      filterParties(value) {
        console.log(value);
        this.subscriptions.push(this.partyService.getParties({search: value}).subscribe(data => {
          console.log(data);
          if(data.body.next) {
            this.getMoreParties = true;
          }
          this.partyToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
          this.parties = data.body.results;
          if(this.partyToken) this.getMoreParties = true
        }))
      }

      addressAdd(ev) {
        this.fullAddress = ev;
        this.addedAddress.emit(ev);
      }
    
      addressUpdate(ev) {
        this.fullAddress = ev;
        this.updatedAddress.emit(ev);
      }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s=>s.unsubscribe());
    }

    public deleteCurrentPerson() {
        console.log(this.personId);
        this.deletePerson.emit(this.personId);
        setTimeout(()=> {
            this.router.navigate(['/pages/people']);
        }, 1000);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'person')) {
            this.initEditPersonForm();
            this.initEditUserForm();
            if(this.person) {
                this.userGroups = this.person.groups;
                if(this.person.addressId) {
                    this.getAddress(this.person.addressId)
                }
                if(this.person.picture !== undefined && this.person.picture !== null
                    && this.person.picture !== '') {
                        this.innerImage = this.mediaUrl + this.person.picture;
                        this.pic = true;
                }
                if(this.person.user) {
                    this.userExists = true;
                }
            }
        }
    }

    public ifEditPersonFormControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.editPersonForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public ifEditUserControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.editUserForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public submit(): void {
        if(this.editPersonForm.controls['party'].value == '') {
            this.partyNotSelected = true;
            this.tosterService.error('Please select a party');
        }
        if(this.editPersonForm.controls['addressId'].value == '') {
            this.tosterService.error('Please select an address');
        }
        this.editPersonForm.markAllAsTouched();
        if (this.newUser) {
            this.editUserForm.markAllAsTouched();
        }
        if (this.editPersonForm.invalid || (this.editUserForm.invalid && this.newUser)) {
            console.log('error');
            // if (!this.profilePicture && !this.userExists) {
            //     this.noProfilePictureError = true;
            // }
            return;
        }
        const personUser: AddUpdatePersonUserInterface = {
            person: {
                id: this.person ? this.person.id : undefined,
                phone: this.editPersonForm.controls['phone'].value,
                addressId: this.editPersonForm.controls['addressId'].value,
                job: this.editPersonForm.controls['job'].value,
                party: this.editPersonForm.controls['party'].value,
                firstName: this.editPersonForm.controls['firstName'].value,
                lastName: this.editPersonForm.controls['lastName'].value,
                picture: this.profilePicture,
                groups: this.editPersonForm.controls['userGroups'].value
            },
            personFormIsDirty: this.editPersonForm.dirty,
            farms: this.editPersonForm.controls['activeFarms'].value,
            groups: this.editPersonForm.controls['userGroups'].value
        };
        if(!this.profilePicture && !this.pic) {
            this.noProfilePictureError = true;
        }
        console.log(personUser);
            this.addUpdatePersonUser.emit(personUser);
        
    }

    public addButtonClick(): void {
        this.newUser = true;
        this.editUserForm.reset();
    }

    public deleteButtonClick(): void {
        if(!this.newUser) {
            this.deleteUserModal.showModal();
        }
        else {
            this.newUser = false;
        }
    }

    public editParty(party: PartyModel): void {
        this.selectedParty = party;
        this.addEditPartyModal.showModal();
    }

    public onDeleteParty(party: PartyModel): void {
        this.selectedParty = party;
        this.deleteParty.emit(this.selectedParty.id);
        if (this.editPersonForm.controls['party'].value === this.selectedParty.id) {
            this.editPersonForm.controls['party'].setValue(null);
        }
    }

    public editGroup(group: number): void {
        this.selectedGroup = group;
        this.addEditGroupModal.showModal();
    }

    public addGroupModal(): void {
        this.selectedGroup = null;
        this.addEditGroupModal.showModal();
    }

    public addPartyModal(): void {
        this.selectedParty = null;
        this.addEditPartyModal.showModal();
    }

    public imageChanged(file: File): void {
        this.profilePicture = file;
        this.noProfilePictureError = false;
        this.editPersonForm.markAsDirty();
    }

    public createdGroup(group: PeopleGroupModel): void {
        if(!this.userGroups.includes(group.id)) {
            this.userGroups.push(group.id);
        }
        this.addEditGroupModal.hideModal();
    }

    private initEditUserForm(): void {
        this.editUserForm = this.formBuilder.group({
            username: [this.person && this.person.user ? this.person.user.fullName : ''],
            email: [this.person && this.person.user ? this.person.user.email : '', Validators.compose([Validators.email, Validators.required])
            ],
            userType: [this.person && this.person.user && this.person.role ? this.person.role : 10],
            password: ['', Validators.compose([
                    // Validators.required,
                    Validators.minLength(8),
                    PasswordPatternValidator.patternValidator(/\d/, { hasNumber: true }),
                    PasswordPatternValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
                    PasswordPatternValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
                    // PasswordPatternValidator.patternValidator(/[ [!@#$%^&*()_+-=[]{};':"|,.<>/?]/](<mailto:!@#$%^&*()_+-=[]{};':"|,.<>/?]/>),
                    //  { hasSpecialCharacters: true })
                ])
            ],
            confirmPassword: ['']
        },
        {
            validator: FapConfirmPasswordValidator.matchPassword
         });
    }

    private initEditPersonForm(): void {
        this.editPersonForm = this.formBuilder.group({
            firstName: [this.person && this.person.firstName ? this.person.first_name ? this.person.first_name : this.person.firstName : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            lastName: [this.person && this.person.lastName ? this.person.last_name ? this.person.last_name : this.person.lastName : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            addressId: [this.person && this.person.addressId ? this.person.addressId : '', Validators.required],
            phone: [this.person && this.person.phone ? this.person.phone : ''],
            job: [this.person && this.person.job ? this.person.job : ''],
            party: [this.person && this.person.party ? this.person.party.id : '', Validators.compose([
                    Validators.required
                ])
            ],
            activeFarms: [this.person && this.person.activeFarms ? this.person.activeFarms : []],
            userGroups: [this.person && this.person.groups ? this.person.groups : []]
        });

        if(this.person) {
            this.selectedPartyId = this.person.party.id;
            this.checkDefaultPartyExistence();
        }
    }

    private initDeleteUserModalButtons(): void {
        const _this: FapAddEditPersonComponent = this;

        this.deleteUserModalButtonPrimaryInterface = {
            clickFunction(): void { _this.deleteUser.emit(_this.person.user.id);
                                    _this.deleteUserModal.hideModal(); },
            text: this.translateService.instant('yes')
        };

        this.deleteUserModalButtonSecondaryInterface = {
            clickFunction(): void { _this.deleteUserModal.hideModal(); },
            text: this.translateService.instant('cancel')
        };
    }

    private initDeleteGroupModalButtons(): void {
        this.deleteGroupModalButtonPrimaryInterface = {
            clickFunction: (): void => {
                this.deleteGroup.emit(this.selectedGroup);
                this.deleteGroupModal.hideModal();
                this.userGroups = this.userGroups.filter((groupId: number): boolean => groupId !== this.selectedGroup); },
            text: this.translateService.instant('yes')
        };
        this.deleteGroupModalButtonSecondaryInterface = {
            clickFunction: (): void => { this.deleteGroupModal.hideModal(); },
            text: this.translateService.instant('cancel')
        };
    }

    public addAddressModal(): void {
        this.selectedAddress = null;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    public editAddress(party: AddressModel): void {
        this.selectedAddress = party;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

}
