import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'fap-data-matrix-selection',
  templateUrl: './fap-data-matrix-selection.component.html',
  styleUrls: ['./fap-data-matrix-selection.component.scss']
})
export class FapDataMatrixSelectionComponent implements OnDestroy {

  @Input() public data: any[] = [];
  @Input() public title: string;
  @Input() public items: any[] = [];
  @Input() public selectedItems: any[] = [];
  @Input() public type: string;

  public rangeFrom;
  public rangeTo;

  @Output() public emitSelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() public hideModal: EventEmitter<any> = new EventEmitter();
 
  constructor() {}

  checkDataPresence(value: any) {
    return this.selectedItems.some(obj => obj === value);
  }

  toggleItemSelection(value) {
    const index = this.selectedItems.indexOf(value); // Check if the value exists in the array
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(value);
    }
    console.log(this.selectedItems);
  }
  
  updateRange(type: string, value) {
    if(type === 'from') {
      this.rangeFrom = value
    } else {
      if(this.rangeFrom && value <= this.rangeFrom) {
        this.rangeTo = this.rangeFrom;
      } else if(this.rangeFrom && value > this.items.length) {
        this.rangeTo = this.items.length;
      } else {
        this.rangeTo = value
      }
    }
  }

  selectRange() {
    if(!this.rangeFrom || !this.rangeTo) {
      return;
    }

    this.selectedItems = this.items.slice(this.rangeFrom - 1, this.rangeTo).map(item => (item.id).toString());
    console.log(this.selectedItems);
  }

  clear() {
    this.selectedItems = [];
    this.rangeFrom = null;
    this.rangeTo = null;
  }

  selectEven() {
    this.selectedItems = this.items.filter((_, index) => index % 2 === 0).map(item => (item.id).toString());
    console.log(this.selectedItems);
  }

  selectOdd() {
    this.selectedItems = this.items.filter((_, index) => index % 2 !== 0).map(item => (item.id).toString());
    console.log(this.selectedItems);
  }

  saveSelection() {
    this.emitSelectedItems.emit(this.selectedItems);
    this.clearConfigs();
  }

  closeModal() {
    this.clearConfigs();
    this.hideModal.emit();
  }

  clearConfigs() {
    this.rangeFrom = null;
    this.rangeTo = null;
  }

  ngOnDestroy(): void {
    this.clearConfigs();
  }

}
