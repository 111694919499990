<form [formGroup]="addEditSchedulerForm" class="col-12 p-0">
    <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput2', scheduler, addEditSchedulerTranslationPopup, 'addEditSchedulerForm')">
        <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
        <input matInput formControlName="name" readonly #nameInput2>
        <mat-error *ngIf="addEditSchedulerForm.controls['name_t'].errors?.required">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
        </mat-error>
    </mat-form-field>

    <div class="day_info row">
        <div class="overlay_parent month col-6">
            <div class="thin_overlay" (click)="showMatrixModal('month', availableMonths)"></div>
            <mat-form-field class="w-100">
                <mat-label>Month</mat-label>
                <mat-select
                    formControlName="month"
                    required
                    multiple
                >
                    <div class="scroll_wrap">
                        <mat-option
                            *ngFor="let month of availableMonths" [value]="month?.id">
                            {{month?.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="overlay_parent day col-6">
            <div class="thin_overlay" (click)="showMatrixModal('day', availableDates)"></div>
            <mat-form-field class="w-100">
                <mat-label>Day</mat-label>
                <mat-select
                    formControlName="day"
                    required
                    multiple
                    (selectionChange)="changeDay($event)"
                >
                    <div class="scroll_wrap">
                        <mat-option
                            *ngFor="
                                let date of availableDates" [value]="date?.id">
                            {{date?.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="overlay_parent dweek mb-2">
        <div class="thin_overlay" (click)="showMatrixModal('dweek', availableDays)"></div>
        <div class="days_container">
            <mat-form-field class="w-100">
                <mat-label>Week</mat-label>
                <mat-select
                    formControlName="dweek"
                    required
                    multiple
                    (selectionChange)="changeDay($event)"
                >
                    <div class="scroll_wrap">
                        <mat-option
                            *ngFor="
                                let date of availableDays" [value]="date?.id">
                            {{date?.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="time_info row">
            <div class="overlay_parent hours col-6">
            <div class="thin_overlay" (click)="showMatrixModal('hour', availableHours)"></div>
                <mat-form-field class="w-100">
                    <mat-label>Hour</mat-label>
                    <mat-select
                        formControlName="hour"
                        required
                        multiple
                    >
                        <div class="scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let hour of availableHours" [value]="hour.id">
                                {{hour?.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="overlay_parent minute col-6">
                <div class="thin_overlay" (click)="showMatrixModal('minute', availableMinutes)"></div>
                <mat-form-field class="w-100">
                    <mat-label>Minute</mat-label>
                    <mat-select
                        formControlName="minute"
                        required
                        multiple
                    >
                        <div class="scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let minute of availableMinutes" [value]="minute.id">
                                {{minute?.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
    </div>
    <div class="action_btns d-flex justify-content-end p-0">
        <button class="btn mr-2" (click)="submitScheduler(); closeModal.emit();">{{ 'translations.save' | translate }}</button>
        <button class="btn" (click)="closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
    </div>
</form>

<fap-modal #addEditSchedulerTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.scheduler)" 
        [prefix]="'SCHEDULER_'" 
        [translation]="translation"
        [label]="label"
        [group]="'schedulers'"
        (submitTranslation)="onAddUpdateSchedulerTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #matrixModal [inputTitle]="matrixType | titlecase" [showFooter]="false">
    <fap-data-matrix-selection 
        [type]="matrixType" 
        [selectedItems]="selectedItems" 
        [title]="matrixType | titlecase" 
        [items]="matrixItems"
        (emitSelectedItems)="setItems(matrixType, $event)"
        (hideModal)="matrixModal.hideModal()"
    >
    </fap-data-matrix-selection>
</fap-modal>