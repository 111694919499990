import { AttributeService } from './../services/api/attribute/attribute.service';
import { AttributeModel } from './../models/attribute/attribute.model';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router
} from '@angular/router';
import {
    Observable,
    of,
    combineLatest
} from 'rxjs';

import {
    catchError,
    map
} from 'rxjs/operators';

import { GlobalRegistryService } from '../global-registry/global-registry.service';
import { UserService } from '../services/api/user/user.service';
import { SystemDataService } from '../services/api/system/system.service';
import { ResponseModel } from '../models/response.model';
import { UserModel } from '../models/user/user.model';
import { SystemDataInterface } from '../global-registry/system-data.interface';
import { FarmService } from '../services/api/farm/farm.service';
import { FarmModel } from '../models/farm/farm.model';
import { TypesService } from '../services/api/types/types.service';
import { PartyService } from '../services/api/company/party.service';
import { CompanyService } from '../services/api/company/company.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { RolesEnum } from '../enums/roles.enum';
import { PeopleGroupModel } from '../models/groups/people-group.model';
import { PeopleGroupService } from '../services/api/people-group/people-group.service';
import { LotModel } from '../models/lot/lot.model';
import { LotService } from '../services/api/farm/lot.service';
import { ResourceTypeModel } from '../models/type/resource-type.model';
import { UnitModel } from '../models/units/unit.model';
import { UnitService } from '../services/api/units/unit.service';
import { TranslationModel } from '../models/type/translation-type.model';
import { CompanyModel } from '../models/company/company.model';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../shared/services/nav.service';
import { PlanModel } from '../models/company/plan.model';
import { BaseTypeModel } from '../models/base/base-type.model';
import { PersonModel } from '../models/person/person.model';


@Injectable()
export class BasePermissionsGuard implements CanActivate {

    constructor(private globalRegistry: GlobalRegistryService,
                private router: Router,
                private userService: UserService,
                private systemDataService: SystemDataService,
                private farmService: FarmService,
                private lotService: LotService,
                private typesService: TypesService,
                private partyService: PartyService,
                private companyService: CompanyService,
                private peopleGroupService: PeopleGroupService,
                private unitService: UnitService,
                private permissionService: NgxPermissionsService,
                private translate: TranslateService,
                private navServices: NavService,
                private attributeService: AttributeService) {
    }

    public canActivate(): Observable<boolean> | boolean {
        if(localStorage.getItem('auth_token')) {
        return this.createObservable().pipe(catchError((): Observable<boolean> => {
            this.redirectToLoginPage();
            return of(false);
        }));
        } else {
            this.router.navigate(['/overview'], {queryParams: {lang: this.language}})
        }
    }

    private createObservable(): Observable<any> {
        /**
         * Here we have to handle the next requests:
         * - get user data
         * - get team data
         * - get types
         */
        return combineLatest([
            this.userService.getUser(),
            this.farmService.getFarms(),
            this.lotService.getLots(),
            
            this.typesService.getResourceTypes(),
            
            this.companyService.getPersons(),
            this.peopleGroupService.getPeopleGroups(),
            this.typesService.getAttributes(),
            this.typesService.getTranslations(),
            this.companyService.getPlans(),
            this.companyService.getCountries(),
            this.companyService.getCompany(),
            this.typesService.getGenericTypes(),
            this.unitService.getUnits()]).pipe(map(([userResponse,
                farmsResponse,
                lotsResponse,
                
                resourceTypesResponse,
                
                personsResponse,
                peopleGroupsResponse,
                attributesResponse,
                translationResponse,
                plansResponse,
                countriesResponse,
                companyResponse,
                typesResponse,
                unitsResponse]
                : [ResponseModel<UserModel>,
                    ResponseModel<FarmModel[]>,
                    ResponseModel<LotModel[]>,
                    
                    ResponseModel<ResourceTypeModel[]>,
                   
                    ResponseModel<PersonModel[]>,
                    ResponseModel<PeopleGroupModel[]>,
                    ResponseModel<AttributeModel[]>,
                    ResponseModel<TranslationModel[]>,
                    ResponseModel<PlanModel[]>,
                    ResponseModel<[]>,
                    ResponseModel<CompanyModel>,
                    ResponseModel<BaseTypeModel[]>,
                    ResponseModel<UnitModel[]>
                ]): boolean => {
                this.handleUserData(userResponse.model);

                this.handleSystemData({
                    farms: farmsResponse.model,
                    lots: lotsResponse.model,
                    
                    persons: personsResponse.body.results,
                    peopleGroups: peopleGroupsResponse.model,
                    user: userResponse.model,
                    resourceTypes: resourceTypesResponse.body.results,
                    units: unitsResponse.model,
                    attributes: attributesResponse.model,
                    translations: translationResponse.model,
                    plans: plansResponse.model,
                    countries: countriesResponse.body,
                    company: companyResponse.model,
                    types: typesResponse.model
                });

                this.globalRegistry.systemData.unitsMap = new Map<number, any[]>();
                this.globalRegistry.createObjectMap(unitsResponse.model, this.globalRegistry.systemData.unitsMap);

                this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
                this.globalRegistry.createObjectMap(translationResponse.model, this.globalRegistry.systemData.translationsMap);

                this.globalRegistry.systemData.personsMap = new Map<number, any[]>();
                this.globalRegistry.createObjectMap(personsResponse.body.results, this.globalRegistry.systemData.personsMap);
                
                localStorage.setItem('timezone', userResponse.body.results.profile.data.timezone);

                this.globalRegistry.isLoading = false;
                this.globalRegistry.isDataLoaded = true;
                this.globalRegistry.isLoggedIn = true;
                return this.globalRegistry.isDataLoaded;
            }));

    }

    private handleUserData(userData: UserModel): void {
        if (userData) {
            // set the user permissions
            switch (userData.profile.role) {
                case RolesEnum.CompanyAdmin:
                    this.permissionService.loadPermissions(['COMPANY_ADMIN']);
                    break;
                case RolesEnum.FarmAdmin:
                    this.permissionService.loadPermissions(['FARM_ADMIN']);
                    break;
                default:
                    this.permissionService.loadPermissions(['WORKER']);
            }
            this.globalRegistry.isLoggedIn = true;
            this.globalRegistry.initUserData(userData);
            if(localStorage.getItem('language')) {
                this.navServices.setCurrentLanguage(localStorage.getItem('language'));
            } else {
                if(userData.profile.data.language) {
                    localStorage.setItem('language', userData.profile.data.language)
                    this.translate.use(userData.profile.data.language);
                    this.navServices.setCurrentLanguage(userData.profile.data.language);
                } else {
                    localStorage.setItem('language', 'en')
                    this.translate.use('en');
                    this.navServices.setCurrentLanguage('en');
                }
            }
        } else {
            this.redirectToLoginPage();
        }
    }

    public get language() {
        return navigator.language.substr(0, 2);
    }

    private redirectToLoginPage(): void {
        this.router.navigate(['/overview'])
    }

    private handleSystemData(systemData: SystemDataInterface): void {
        if (systemData) {
            this.globalRegistry.initSystemData(systemData);
            // this.globalRegistry.reloadForecasts();
        } else {
            this.redirectToLoginPage();
        }
    }
}
